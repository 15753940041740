import React, { useRef, useState, useEffect, forwardRef } from "react";
import Slider from "react-slick";
import { useIsMobile, useLoadOnVisible } from "../../hooks";
import { motion } from "framer-motion";

import video1 from "assets/images/landingAir/air-insights/real-time-air-alert.mp4";
import video2 from "assets/images/landingAir/air-insights/current-room-status.mp4";
import video3 from "assets/images/landingAir/air-insights/monthly-home-health-report.mp4";
import video4 from "assets/images/landingAir/air-insights/life-saving-co-sensor.mp4";

import video1Thumbnail from "assets/images/landingAir/air-insights/real-time-air-alert-thumbnail.jpg";
import video2Thumbnail from "assets/images/landingAir/air-insights/current-room-status-thumbnail.jpg";
import video3Thumbnail from "assets/images/landingAir/air-insights/monthly-home-health-report-thumbnail.jpg";
import video4Thumbnail from "assets/images/landingAir/air-insights/life-saving-co-sensor-thumbnail.jpg";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const data = [
  {
    duration: 10000,
    title: (
      <>
        Your home’s health,
        <br />
        on display.
      </>
    ),
    content:
      "Mila tips you off when something isn't right and lets you know when everything is ok—in plain (and fun!) English.",
    videoUrl: video1,
    poster: video1Thumbnail,
  },
  {
    duration: 3000,
    title: (
      <>
        Peace of mind,
        <br />
        in your pocket.
      </>
    ),
    content:
      "Is the air in the kids’ room ok? Has your living room been struck by the evening’s fish dinner? Check in on your home anytime, from anywhere, through Mila’s mobile app.",
    videoUrl: video2,
    poster: video2Thumbnail,
  },
  {
    duration: 25000,
    title: (
      <>
        Your air quality,
        <br />
        demystified.
      </>
    ),
    content:
      "Mila is the first purifier to deliver a comprehensive monthly home health report, revealing air trends she’s uncovered in your home and neighborhood. Airblivious? Nope, not you.",
    videoUrl: video3,
    poster: video3Thumbnail,
  },
  {
    duration: 4000,
    title: (
      <>
        Looking out for you <br />
        and your entire crew.
      </>
    ),
    content:
      "Mila is the first air purifier equipped with a carbon monoxide sensor, so you’ll know if this odorless gas might be lurking in your air.",
    videoUrl: video4,
    poster: video4Thumbnail,
  },
];

const AirInsights = () => {
  const isMobile = useIsMobile(769);
  const sliderRef = useRef(null);
  const sliderWrapRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [slideDuration, setSlideDuration] = useState(data[0].duration);
  const [progress, setProgress] = useState(-100);
  const [slideInView, setSlideInView] = useState(false);
  const isSliderInView = useLoadOnVisible(sliderWrapRef, { threshold: 0.2 });

  useEffect(() => {
    if (slideInView) return;
    setSlideInView(isSliderInView);
  }, [isSliderInView]);

  useEffect(() => {
    setSlideDuration(isMobile ? data[nextIndex].duration : 0);
  }, [isMobile, nextIndex]);

  useEffect(() => {
    if (!slideInView) return;
    const currentVideo = document.querySelector(
      ".air-insights__slider .slick-current .air-insights__video"
    );

    if (!isMobile) return;

    const allVideos = document.querySelectorAll(
      ".air-insights__slider .air-insights__video"
    );
    allVideos.forEach((video) => {
      video.pause();
      video.currentTime = 0;
    });

    setTimeout(() => {
      currentVideo?.play();
    }, 100);
  }, [currentVideoIndex, isMobile, slideInView, nextIndex]);

  useEffect(() => {
    if (slideDuration === 0) return;
    setProgress(-100);

    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 0 ? -100 : prev + 1));
    }, slideDuration * 0.01);
    return () => clearInterval(interval);
  }, [nextIndex, slideDuration, isMobile, slideInView]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: slideDuration,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    afterChange: (idx) => {
      setCurrentVideoIndex(idx);
    },
    beforeChange: (idx, nextIdx) => {
      setNextIndex(nextIdx);
      setSlideDuration(data[nextIdx].duration);
    },
  };
  return (
    <section className="air-insights">
      <div className="air-insights__card">
        <div className="container">
          <div className="air-insights__header">
            <motion.h2
              className="air-insights__heading"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.02 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              She safeguards your home from the dangers you can’t see or smell.
            </motion.h2>
            <motion.p
              className="air-insights__short-intro"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.03 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              It’s impossible to know WTF is in your air—until now. Mila’s got 8
              smart sensors on the scene, <br /> and they don’t miss a thing.
            </motion.p>
          </div>
          {isMobile ? (
            <div className="air-insights__slider-wrapper" ref={sliderWrapRef}>
              {isSliderInView && (
                <Slider
                  className="air-insights__slider"
                  {...settings}
                  ref={sliderRef}
                  style={{ "--progress": `${progress}%` }}
                >
                  {data.map((item, index) => (
                    <Item {...item} key={index} isMobile={isMobile} />
                  ))}
                </Slider>
              )}
            </div>
          ) : (
            <div className="air-insights__grid">
              {data.map((item, index) => (
                <Item {...item} key={index} index={index} />
              ))}
            </div>
          )}
          <div className="air-insights__details footer-content">
            <h4>Fun Fact 🤓</h4>
            <p>
              86% of Mila owners say they know more about their home’s health
              within the first week of use.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const Item = forwardRef(
  ({ videoUrl, title, content, index, isMobile, poster }, ref) => {
    const videoRef = useRef(null);
    const isInView = useLoadOnVisible(videoRef, { threshold: 0.2 });

    return (
      <motion.div
        className="air-insights__item"
        key={title}
        initial={{ opacity: 0 }}
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.01 * index }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        ref={videoRef}
      >
        <div className="air-insights__content">
          <h3 className="air-insights__title">{title}</h3>

          <div className="air-insights__text">
            <p>{content}</p>
          </div>
        </div>
        <div className="air-insights__media">
          <video
            src={videoUrl}
            className="air-insights__video"
            playsInline
            poster={poster}
            autoPlay={!isMobile}
            loop
            muted
            ref={ref}
          ></video>
        </div>
      </motion.div>
    );
  }
);

export default AirInsights;
